<script lang="ts" context="module">
    export interface IModal<C extends SvelteComponent> {
        component: ComponentType<C>
        props: ComponentProps<C>
        id?: string
    }

    export interface IModalVC {
        push: <C extends SvelteComponent>(modal: IModal<C>) => void
        pop: () => void
        popToRoot: () => void
        remove: (id: string) => void
        count$: BehaviorSubject<number>
    }

    /**
     * Returns a Model that can be pushed, which asks the user whether they want to leave the group.
     *
     * If no `group` is given, it does not "only" leave the group, but navigates to `/` instead.
     */
    export function LEAVE_GROUP_MODAL(group: Group | null): IModal<ModalConfirmCancel> {
        return {
            component: ModalConfirmCancel,
            props: {
                title: get(lang).app.Leave_Group(),
                text: get(lang).app.Leave_Group_Modal(),
                confirmText: get(lang).app.Leave(),
                confirmColor: "orange",
                cancelText: get(lang).app.Stay(),
                confirmAction: () => (group ? group.disconnect() : goto("/")),
            },
        }
    }
</script>

<script lang="ts">
    import { browser } from "$app/environment"
    import { BehaviorSubject } from "rxjs"
    import { goto } from "$app/navigation"
    import lang from "$lang"
    import type { Group } from "$lib/group/Group"
    import { SvelteComponent, setContext, type ComponentProps, type ComponentType } from "svelte"
    import { get, writable, type Writable } from "svelte/store"
    import ModalConfirmCancel from "./ModalConfirmCancel.svelte"

    const modals$: Writable<IModal<any>[]> = writable([])
    const count$ = new BehaviorSubject(0)
    $: count$.next($modals$.length)

    // on desktop this hides the scrollbar, disabling scrolling alltogether
    $: updateBodyScroll($modals$.length > 0)
    function updateBodyScroll(allow: boolean) {
        if (!browser) return
        if (allow) {
            document.body.classList.add("overflow-hidden")
        } else {
            document.body.classList.remove("overflow-hidden")
        }
    }

    function push<C extends SvelteComponent>(modal: IModal<C>) {
        console.info(`[Modal] push(${modal.component.name})`)
        modals$.update((modals) => {
            modals.push(modal)
            return modals
        })
    }
    function pop() {
        console.info(`[Modal] pop()`)
        modals$.update((modals) => {
            modals.pop()
            return modals
        })
    }
    function popToRoot() {
        console.info(`[Modal] popToRoot()`)
        modals$.update(() => {
            return []
        })
    }
    function remove(id: string) {
        console.info(`[Modal] remove(${id})`)
        modals$.update((modals) => {
            return modals.filter((modal) => modal.id !== id)
        })
    }

    setContext<IModalVC>("ModalVC", {
        push,
        pop,
        popToRoot,
        remove,
        count$,
    })
</script>

<div class="relative h-full w-full">
    <div
        class="absolute inset-0 z-40 bg-black/75 backdrop-blur-sm transition-opacity duration-300
            {$modals$.length > 0 ? 'opacity-100' : 'pointer-events-none opacity-0'}"
    >
        <!-- DEBUG: -->
        <!-- <p class="whitespace-pre-line">
            {JSON.stringify(
                $modals$.map((modal) => modal.component.name),
                null,
                2,
            )}
        </p> -->
        {#each $modals$ as modal, i}
            <div
                on:touchmove|stopPropagation={() => {}}
                class="absolute inset-0 z-40 flex items-center justify-center
                        {i !== $modals$.length - 1 ? 'hidden' : ''}"
            >
                <svelte:component
                    this={modal.component}
                    {...modal.props}
                    hidden={i !== $modals$.length - 1}
                />
            </div>
        {/each}
    </div>

    <slot />
</div>
